/* You can add global styles to this file, and also import other style files */


.navbar .navbar-item.navbar-dropdown {
    margin-left: auto;
}
.layout-px-spacing {
    min-height: calc(100vh - 96px)!important;
}




.droppable-active { background-color: rgba(242, 250, 139, 0.4) !important; }
.tools a { cursor: pointer; font-size: 80%; }
.form-body .col-md-6, .form-body .col-md-12, .form-body .col-md-4 { min-height: 100px;  }
.draggable { cursor: move; }
.formbuilder-dynamicrow {
  /*border: 2px;
  border: 2px solid blue;
  border-radius: 25px;*/
  padding: 10px 10px 10px 10px; margin-top: 20px;
}







.widget-table-with-lic {
  padding: 25px 23px;
  background: #fff;
}
.widget-table-with-lic .w-header {
  display: flex;
  justify-content: space-between;
}
.widget-table-with-lic .w-header .w-info {
  align-self: center;
}
.widget-table-with-lic .w-header .w-info h6 {
  font-weight: 600;
  margin-bottom: 0;
  color: #0e1726;
  font-size: 23px;
  letter-spacing: 0;
}
.widget-table-with-lic .w-header .task-action svg {
  color: #888ea8;
  width: 19px;
  height: 19px;
}

.widget-table-with-lic .w-header .task-action .dropdown-menu {
  transform: translate3d(-141px, 0, 0px);
}
.widget-table-with-lic .w-content {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}
.widget-table-with-lic .w-content .w-info p.value {
  font-weight: 500;
  margin-bottom: 0;
  color: #e95f2b;
  font-size: 30px;
}
.widget-table-with-lic .w-content .w-info p.value span {
  font-size: 15px;
  color: #030305;
  font-weight: 600;
  letter-spacing: 0;
}
.widget-table-with-lic .w-content .w-info p.value svg {
  width: 16px;
  height: 16px;
  color: #009688;
  margin-top: 7px;
}
.widget-table-with-lic .w-progress-stats {
  display: flex;
  margin-top: 36px;
}
.widget-table-with-lic .w-icon {
  color: #5f0a87;
  align-self: center;
  justify-content: center;
  border-radius: 50%;
}
.widget-table-with-lic .progress {
  height: 8px;
  margin-bottom: 0;
  height: 20px;
  padding: 4px;
  border-radius: 20px;
  box-shadow: 0 2px 2px rgba(224, 230, 237, 0.4588235294117647);
  width: 100%;
  align-self: flex-end;
  margin-right: 22px;
  margin-left: 22px;
  background-color: rgba(98, 199, 246, 0.14);
}
.widget-table-with-lic .progress-bar.bg-gradient-primary {
  position: relative;
  background-color: #0099da;
  background-image: linear-gradient(315deg, #0099da 0%, #4361ee 74%);
}
.widget-table-with-lic .progress-bar:before {
  content: '';
  height: 6px;
  width: 6px;
  background: #fff;
  position: absolute;
  right: 3px;
  border-radius: 50%;
  top: 3px;
}
.widget-table-with-lic .w-icon p {
  margin-bottom: 0;
  color: #0099da;
  font-size: 15px;
  font-weight: 700;
}
.widget-table-with-lic .w-lic-count p {
  margin-bottom: 0;
  color: #474747;
  font-size: 15px;
  font-weight: 700;
}


body.dark .widget.widget-card-standard-dropdown {
  padding: 25px 23px;
  background: #0e1726;
}
body.dark .widget-card-standard-dropdown .w-header {
  display: flex;
  justify-content: space-between;
}
body.dark .widget-card-standard-dropdown .w-header .w-info {
  align-self: center;
}
body.dark .widget-card-standard-dropdown .w-header .w-info h6 {
  font-weight: 600;
  margin-bottom: 0;
  color: #e0e6ed;
  font-size: 23px;
  letter-spacing: 0;
}
body.dark .widget-card-standard-dropdown .w-header .task-action svg {
  color: #888ea8;
  width: 19px;
  height: 19px;
}
body.dark .widget-card-standard-dropdown .w-header .task-action .dropdown-menu {
  transform: translate3d(-141px, 0, 0px);
}
body.dark .widget-card-standard-dropdown .w-content {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
}
body.dark .widget-card-standard-dropdown .w-content .w-info p.value {
  font-weight: 500;
  margin-bottom: 0;
  color: #e95f2b;
  font-size: 30px;
}
body.dark .widget-card-standard-dropdown .w-content .w-info p.value span {
  font-size: 15px;
  color: #e0e6ed;
  font-weight: 500;
  letter-spacing: 0;
}
body.dark .widget-card-standard-dropdown .w-content .w-info p.value svg {
  width: 16px;
  height: 16px;
  color: #009688;
  margin-top: 7px;
}
body.dark .widget-card-standard-dropdown .w-progress-stats {
  display: flex;
  margin-top: 36px;
}
body.dark .widget-card-standard-dropdown .w-icon {
  color: #5f0a87;
  align-self: center;
  justify-content: center;
  border-radius: 50%;
}
body.dark .widget-card-standard-dropdown .progress {
  height: 8px;
  margin-bottom: 0;
  height: 20px;
  padding: 4px;
  border-radius: 20px;
  width: 100%;
  align-self: flex-end;
  margin-right: 22px;
  background-color: rgba(246, 112, 98, 0.14);
}
body.dark .widget-card-standard-dropdown .progress-bar.bg-gradient-secondary {
  position: relative;
  background-color: #fc5296;
  background-image: linear-gradient(315deg, #fc5296 0%, #f67062 74%);
}
body.dark .widget-card-standard-dropdown .progress-bar:before {
  content: "";
  height: 6px;
  width: 6px;
  background: #fff;
  position: absolute;
  right: 3px;
  border-radius: 50%;
  top: 3px;
}
body.dark .widget-card-standard-dropdown .w-icon p {
  margin-bottom: 0;
  color: #e95f2b;
  font-size: 15px;
  font-weight: 700;
}




.widget.widget-card-standard-dropdown {
  padding: 25px 23px;
  background: #fff;
}

.widget-card-standard-dropdown .w-header {
  display: flex;
  justify-content: space-between;
}
.widget-card-standard-dropdown .w-header .w-info {
  align-self: center;
}
.widget-card-standard-dropdown .w-header .w-info h6 {
  font-weight: 600;
  margin-bottom: 0;
  color: #0e1726;
  font-size: 23px;
  letter-spacing: 0;
}
.widget-card-standard-dropdown .w-header .task-action svg {
  color: #888ea8;
  width: 19px;
  height: 19px;
}
.widget-card-standard-dropdown .w-header .task-action .dropdown-menu {
  transform: translate3d(-141px, 0, 0px);
}
.widget-card-standard-dropdown .w-content {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
}
.widget-card-standard-dropdown .w-content .w-info p.value {
  font-weight: 500;
  margin-bottom: 0;
  color: #e95f2b;
  font-size: 30px;
}
.widget-card-standard-dropdown .w-content .w-info p.value span {
  font-size: 15px;
  color: #0e1726;
  font-weight: 700;
  letter-spacing: 0;
}
.widget-card-standard-dropdown .w-content .w-info p.value svg {
  width: 16px;
  height: 16px;
  color: #009688;
  margin-top: 7px;
}
.widget-card-standard-dropdown .w-progress-stats {
  display: flex;
  margin-top: 36px;
}
.widget-card-standard-dropdown .w-icon {
  color: #5f0a87;
  align-self: center;
  justify-content: center;
  border-radius: 50%;
}
.widget-card-standard-dropdown .progress {
  height: 8px;
  margin-bottom: 0;
  height: 20px;
  padding: 4px;
  border-radius: 20px;
  width: 100%;
  align-self: flex-end;
  margin-right: 22px;
  background-color: #ebedf2;
}
.widget-card-standard-dropdown .progress-bar.bg-gradient-secondary {
  position: relative;
  background-color: #fc5296;
  background-image: linear-gradient(315deg, #805dca 0%, #4361ee 74%);
}
.widget-card-standard-dropdown .progress-bar:before {
  content: "";
  height: 6px;
  width: 6px;
  background: #fff;
  position: absolute;
  right: 3px;
  border-radius: 50%;
  top: 3px;
}
.widget-card-standard-dropdown .w-icon p {
  margin-bottom: 0;
  color: #e95f2b;
  font-size: 15px;
  font-weight: 700;
}








html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.geofi-form-pk-tb-header{
  background-color:var(--pk-tb-header-bg-color);
  color:var(--pk-tb-header-text-color);
  min-height: 40px;
  height: 40px;
}
