/*
	===============================
			@Import	Function
	===============================
*/
/*
	===============================
			@Import	Mixins
	===============================
*/
.nav-tabs .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.show, .nav-pills .dropdown:not(.custom-dropdown-icon):not(.custom-dropdown) .dropdown-menu.show {
  top: 36px !important; }

.nav-tabs {
  border-bottom: 1px solid #ebedf2; }
  .nav-tabs svg {
    width: 20px;
    vertical-align: bottom; }
  .nav-tabs .nav-link.active {
    color: #e95f2b;
    background-color: #fff;
    border-color: #e0e6ed #e0e6ed #fff; }
    .nav-tabs .nav-link.active:after {
      color: #e95f2b; }
  .nav-tabs .nav-link:hover {
    border-color: #ebedf2 #ebedf2 #f1f2f3; }

.dropdown-menu {
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.1); }

.nav-tabs .dropdown-item:hover {
  background-color: #f1f2f3;
  color: #515365; }

.nav-tabs li a.disabled {
  color: #e3e4eb !important; }

.nav-pills .nav-item:not(:last-child) {
  margin-right: 5px; }

.nav-pills .nav-link {
  color: #3b3f5c; }
  .nav-pills .nav-link.active:after {
    color: #fff; }

.nav-pills .show > .nav-link {
  background-color: #e95f2b; }

.nav-pills li a.disabled {
  color: #e3e4eb !important; }

h4 {
  font-size: 1.125rem; }

/*
    Simple Tab
*/
.simple-tab .nav-tabs li a {
  color: #3b3f5c; }

.simple-tab .nav-tabs .nav-item.show .nav-link, .simple-tab .nav-tabs .nav-link.active {
  color: #4361ee;
  font-weight: 600;
  background-color: #fff; }

.nav-tabs {
  border-bottom: 1px solid #e0e6ed; }

.simple-tab .tab-content > .tab-pane {
  padding: 20px 0 0 0; }

/*
    Simple Pills
*/
.simple-pills .nav-pills li a {
  color: #3b3f5c; }

.simple-pills .nav-pills .nav-link.active, .simple-pills .nav-pills .show > .nav-link {
  background-color: #4361ee;
  border-color: transparent; }

.simple-pills .tab-content > .tab-pane {
  padding: 20px 0 0 0; }

/*
    Icon Tab
*/
.icon-tab .nav-tabs li a {
  color: #3b3f5c; }

.icon-tab .nav-tabs svg {
  width: 20px;
  vertical-align: bottom; }

.icon-tab .nav-tabs .nav-item.show .nav-link, .icon-tab .nav-tabs .nav-link.active {
  color: #e95f2b;
  background-color: #fff;
  border-color: #e0e6ed #e0e6ed #fff; }

.icon-tab .tab-content > .tab-pane {
  padding: 20px 0 0 0; }

/*
    Icon Pill
*/
.icon-pill .nav-pills li a {
  color: #3b3f5c; }

.icon-pill .nav-pills svg {
  width: 20px;
  vertical-align: bottom; }

.icon-pill .nav-pills .nav-link.active, .icon-pill .nav-pills .show > .nav-link {
  background-color: #e2a03f;
  border-color: transparent; }

.icon-pill .tab-content > .tab-pane {
  padding: 20px 0 0 0; }

/*
    Underline
*/
.underline-content .nav-tabs {
  border-bottom: 1px solid #e0e6ed; }
  .underline-content .nav-tabs li a {
    padding-top: 15px;
    padding-bottom: 15px; }

.underline-content .tab-content > .tab-pane {
  padding: 20px 0 0 0; }

.underline-content .nav-tabs .nav-link.active, .underline-content .nav-tabs .show > .nav-link {
  border-color: transparent;
  border-bottom: 1px solid #805dca;
  color: #805dca;
  background-color: transparent; }

.underline-content .nav-tabs .nav-link.active:hover, .underline-content .nav-tabs .show > .nav-link:hover, .underline-content .nav-tabs .nav-link.active:focus, .underline-content .nav-tabs .show > .nav-link:focus {
  border-bottom: 1px solid #805dca; }

.underline-content .nav-tabs .nav-link:focus, .underline-content .nav-tabs .nav-link:hover {
  border-color: transparent; }

/*
    Animated Underline
*/
.animated-underline-content .nav-tabs {
  border-bottom: 1px solid #e0e6ed; }
  .animated-underline-content .nav-tabs li a {
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative; }

.animated-underline-content .tab-content > .tab-pane {
  padding: 20px 0 0 0; }

.animated-underline-content .nav-tabs .nav-link.active, .animated-underline-content .nav-tabs .show > .nav-link {
  border-color: transparent;
  color: #805dca; }

.animated-underline-content .nav-tabs .nav-link:focus, .animated-underline-content .nav-tabs .nav-link:hover {
  border-color: transparent; }

.animated-underline-content .nav-tabs .nav-link.active:before {
  -webkit-transform: scale(1);
  transform: scale(1); }

.animated-underline-content .nav-tabs .nav-link:before {
  content: "";
  height: 1px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: #805dca;
  -webkit-transform: scale(0);
  transform: scale(0);
  transition: all .3s; }

/*
    Justify Tab
*/
.justify-tab .nav-tabs li a {
  color: #3b3f5c; }

.justify-tab .nav-tabs .nav-item.show .nav-link, .justify-tab .nav-tabs .nav-link.active {
  color: #4361ee;
  background-color: #fff;
  border-color: #e0e6ed #e0e6ed #fff; }

.justify-tab .tab-content > .tab-pane {
  padding: 20px 0 0 0; }

/*
    Justify Pill
*/
.justify-pill .nav-pills li a {
  color: #3b3f5c; }

.justify-pill .nav-pills .nav-link.active, .justify-pill .nav-pills .show > .nav-link {
  background-color: #2196f3;
  border-color: transparent; }

.justify-pill .tab-content > .tab-pane {
  padding: 20px 0 0 0; }

/*
    Justify Centered Tab
*/
.tab-justify-centered .nav-tabs li a {
  color: #3b3f5c; }

.tab-justify-centered .nav-tabs .nav-item.show .nav-link, .tab-justify-centered .nav-tabs .nav-link.active {
  color: #e95f2b;
  background-color: #fff;
  border-color: #e0e6ed #e0e6ed #fff; }

.tab-justify-centered .tab-content > .tab-pane {
  padding: 20px 0 0 0; }

/*
    Justify Centered Pill
*/
.pill-justify-centered .nav-pills li a {
  color: #3b3f5c; }

.pill-justify-centered .nav-pills .nav-link.active, .pill-justify-centered .nav-pills .show > .nav-link {
  background-color: #e2a03f; }

.pill-justify-centered .tab-content > .tab-pane {
  padding: 20px 0 0 0; }

/*
    Justify Right Tab
*/
.tab-justify-right .nav-tabs li a {
  color: #3b3f5c; }

.tab-justify-right .nav-tabs .nav-item.show .nav-link, .tab-justify-right .nav-tabs .nav-link.active {
  color: #4361ee;
  background-color: #fff;
  border-color: #e0e6ed #e0e6ed #fff; }

.tab-justify-right .tab-content > .tab-pane {
  padding: 20px 0 0 0; }

/*
    Justify Right Pill
*/
.pill-justify-right .nav-pills .nav-link.active, .pill-justify-right .nav-pills .show > .nav-link {
  background-color: #2196f3; }

.pill-justify-right .tab-content > .tab-pane {
  padding: 20px 0 0 0; }

/*
    With Icons   
*/
.rounded-pills-icon .nav-pills li a {
  -webkit-border-radius: 0.625rem !important;
  -moz-border-radius: 0.625rem !important;
  -ms-border-radius: 0.625rem !important;
  -o-border-radius: 0.625rem !important;
  border-radius: 0.625rem !important;
  background-color: #f1f2f3;
  width: 100px;
  padding: 8px; }
  .rounded-pills-icon .nav-pills li a svg {
    display: block;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto; }

.rounded-pills-icon .nav-pills .nav-link.active, .rounded-pills-icon .nav-pills .show > .nav-link {
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
  background-color: #009688; }

.rounded-pills-icon .tab-content > .tab-pane {
  padding: 20px 0 0 0; }

/*
    Vertical With Icon
*/
.rounded-vertical-pills-icon .nav-pills a {
  -webkit-border-radius: 0.625rem !important;
  -moz-border-radius: 0.625rem !important;
  -ms-border-radius: 0.625rem !important;
  -o-border-radius: 0.625rem !important;
  border-radius: 0.625rem !important;
  background-color: #ffffff;
  border: solid 1px #e4e2e2;
  padding: 11px 23px;
  text-align: center;
  width: 100px;
  padding: 8px; }
  .rounded-vertical-pills-icon .nav-pills a svg {
    display: block;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto; }

.rounded-vertical-pills-icon .nav-pills .nav-link.active, .rounded-vertical-pills-icon .nav-pills .show > .nav-link {
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
  background-color: #009688;
  border-color: transparent; }

/*
    Rouned Circle With Icons   
*/
.rounded-circle-pills-icon .nav-pills li a {
  background-color: #f1f2f3;
  padding: 20px 20px; }
  .rounded-circle-pills-icon .nav-pills li a svg {
    display: block;
    text-align: center; }

.rounded-circle-pills-icon .nav-pills .nav-link.active, .rounded-circle-pills-icon .nav-pills .show > .nav-link {
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
  background-color: #2196f3; }

.rounded-circle-pills-icon .tab-content > .tab-pane {
  padding: 20px 0 0 0; }

/*
    Vertical Rounded Circle With Icon
*/
.rounded-circle-vertical-pills-icon .nav-pills a {
  background-color: #ffffff;
  border: solid 1px #e4e2e2;
  border-radius: 50%;
  height: 58px;
  width: 60px;
  padding: 16px 18px;
  max-width: 80px;
  min-width: auto; }
  .rounded-circle-vertical-pills-icon .nav-pills a svg {
    display: block;
    text-align: center;
    line-height: 19px; }

.rounded-circle-vertical-pills-icon .nav-pills .nav-link.active, .rounded-circle-vertical-pills-icon .nav-pills .show > .nav-link {
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.3);
  background-color: #2196f3;
  border-color: transparent; }

/*
    Vertical Pill
*/
.vertical-pill .nav-pills .nav-link.active, .vertical-pill .nav-pills .show > .nav-link {
  background-color: #009688; }

/*
    Vertical Pill Right
*/
.vertical-pill-right .nav-pills .nav-link.active, .vertical-pill-right .nav-pills .show > .nav-link {
  background-color: #009688; }

/*
    Creative vertical pill
*/
.vertical-line-pill .nav-pills {
  border-bottom: 1px solid transparent;
  width: 92px;
  border-right: 1px solid #e0e6ed; }
  .vertical-line-pill .nav-pills a {
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative; }
  .vertical-line-pill .nav-pills .nav-link {
    padding: .5rem 0; }
    .vertical-line-pill .nav-pills .nav-link.active {
      position: relative;
      background-color: transparent;
      border-color: transparent;
      color: #805dca;
      font-weight: 600; }
  .vertical-line-pill .nav-pills .show > .nav-link {
    position: relative;
    background-color: transparent;
    border-color: transparent;
    color: #805dca;
    font-weight: 600; }
  .vertical-line-pill .nav-pills .nav-link:focus, .vertical-line-pill .nav-pills .nav-link:hover {
    border-color: transparent; }
  .vertical-line-pill .nav-pills .nav-link.active:before {
    -webkit-transform: scale(1);
    transform: scale(1);
    bottom: 0; }
  .vertical-line-pill .nav-pills .nav-link:before {
    content: "";
    height: 100%;
    position: absolute;
    width: 1px;
    right: -1px;
    background-color: #805dca;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all .3s; }

.vertical-line-pill #v-line-pills-tabContent h4 {
  color: #e2a03f; }

.vertical-line-pill #v-line-pills-tabContent p {
  color: #888ea8; }

.media img {
  border-radius: 50%;
  border: solid 5px #ebedf2;
  width: 80px;
  height: 80px; }

/*
    Border Tab
*/
.border-tab .tab-content {
  border: 1px solid #e0e6ed;
  border-top: none;
  padding: 10px; }
  .border-tab .tab-content > .tab-pane {
    padding: 20px 30px 0 30px; }
  .border-tab .tab-content .media img.meta-usr-img {
    margin-left: -30px; }

/*
    Vertical Border Tab
*/
.vertical-border-pill .nav-pills {
  width: 92px; }
  .vertical-border-pill .nav-pills a {
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative; }
  .vertical-border-pill .nav-pills .nav-link {
    padding: .5rem 0;
    border: 1px solid #e0e6ed;
    border-radius: 0;
    border-bottom: none; }
    .vertical-border-pill .nav-pills .nav-link:last-child {
      border-bottom: 1px solid #e0e6ed; }
    .vertical-border-pill .nav-pills .nav-link.active {
      position: relative;
      color: #fff;
      background-color: #1abc9c; }
  .vertical-border-pill .nav-pills .show > .nav-link {
    position: relative;
    color: #fff;
    background-color: #1abc9c; }

/*
    Border Top Tab
*/
.border-top-tab .nav-tabs {
  border-bottom: 1px solid transparent; }
  .border-top-tab .nav-tabs li a {
    border-radius: 0px;
    padding: 12px 30px;
    background: #f6f7f8;
    color: #0e1726;
    border-right: 1px solid transparent; }

.border-top-tab .tab-content > .tab-pane {
  padding: 20px 0 0 0; }

.border-top-tab .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  border-radius: 0px;
  padding: 12px 30px;
  background: #f6f7f8;
  color: #805dca;
  border: 1px solid transparent;
  border-top: 2px solid #805dca; }

.border-top-tab .nav-tabs .nav-link.active {
  color: #495057;
  border-radius: 0px;
  padding: 12px 30px;
  background: #f6f7f8;
  color: #805dca;
  border: 1px solid transparent;
  border-top: 2px solid #805dca; }

.border-top-tab .nav-tabs .nav-link:focus, .border-top-tab .nav-tabs .nav-link:hover {
  border: 1px solid transparent;
  border-top: 2px solid #805dca; }
